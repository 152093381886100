:root {
    --header-height: 3rem;
    --font-medium: 600;
    --body-color: #f8f9fa;
    --title-color: #111;
    --title-color-dark: #007bff;
    --transition-speed: 0.5s;
}

/* ========== Global Reset ========== */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
    background-color: var(--body-color);
    color: var(--title-color);
    scroll-behavior: smooth;
}

/* ========== Header ========== */
.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: var(--body-color);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  /* 阴影使header更加突出 */
    transition: background-color var(--transition-speed);  /* 交互动画 */
}

.nav {
    height: calc(var(--header-height) + 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
    column-gap: 1rem;
}

.nav__logo {
    font-size: 1.5rem;
    font-weight: var(--font-medium);
    color: var(--title-color-dark);
    transition: color var(--transition-speed);
}

.nav__logo:hover {
    color: var(--title-color-dark);
    transform: scale(1.05);  /* 提升交互感 */
}

.nav__list {
    display: flex;
    column-gap: 2rem;
}

.nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    color: var(--title-color);
    font-weight: var(--font-medium);
    transition: color var(--transition-speed), transform var(--transition-speed);
}

.nav__link:hover {
    color: var(--title-color-dark);
    transform: scale(1.1);
}

.nav__toggle,
.nav__close {
    display: none;
}

/* ========== Responsive Design ========== */
@media screen and (max-width: 768px) {
    .nav__menu {
        position: fixed;
        top: -100%;
        left: 0;
        width: 90%;
        background-color: var(--body-color);
        padding: 2rem;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);  /* 阴影增强菜单弹出效果 */
        border-radius: 0 0 1.5rem 1.5rem;
        transition: top var(--transition-speed);
    }

    .show-menu {
        top: 0;
    }

    .nav__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;
    }

    .nav__toggle {
        display: block;
        font-size: 1.5rem;
        cursor: pointer;
        transition: color var(--transition-speed);
    }

    .nav__close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        font-size: 1.5rem;
        cursor: pointer;
    }

    .nav__logo {
        font-size: 1.2rem;
    }
}

/* ========== Small Devices ========== */
@media screen and (max-width: 350px) {
    .nav__menu {
        padding: 2rem 0.5rem;
    }

    .nav__list {
        grid-template-columns: 1fr;
    }

    .nav__logo {
        font-size: 1rem;
    }
}
